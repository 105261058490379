import React, { useState, useContext } from 'react';
import ContentEditable from 'react-contenteditable'
import { GameContext } from '../contexts';
import checkIcon from '../assets/check.svg';
import checkBlueIcon from '../assets/check-blue.svg';

function QuestionForm({ question, onChange }) {
  return <div style={{maxWidth: 768}}>
    <h2>
      <ContentEditable
        html={question.title || 'Enter question title'}
        onChange={e => onChange({
          ...question,
          title: e.target.value
        })} />
    </h2>
    {Object.keys(question.answers).map(key => <div key={key}>
      <div style={{display: 'flex', marginBottom: 8, fontSize: 25}}>
        <img
          onClick={() => onChange({
            ...question,
            correctAnswer: key
          })}
          src={question.correctAnswer === key ? checkBlueIcon : checkIcon}
          width={32}
          height={32} />
        <span>&nbsp;{key}.&nbsp;</span>
        <ContentEditable
          style={{flex: 1, textAlign: 'left'}}
          html={question.answers[key] || `Answer for ${key}`}
          onChange={e => onChange({
            ...question,
            answers: {
              ...question.answers,
              [key]: e.target.value
            }
          })} />
      </div>
    </div>)}
  </div>
}

export default QuestionForm;
