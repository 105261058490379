import React, { useState, useContext } from 'react';
import { GameContext } from '../contexts';
import QuestionForm from './QuestionForm';

const createSampleQuestion = () => ({
  title: 'Question title',
  answers: {
    A: 'Answer A',
    B: 'Answer B',
    C: 'Answer C',
    D: 'Answer D'
  }
})

function CreateGame() {
  const { state, dispatch } = useContext(GameContext);
  const [questions, setQuestions] = useState([{
    title: 'Sample question: 1 + 1 =',
    answers: {
      A: '1',
      B: '2',
      C: '3',
      D: '4'
    }
  }]);

  return <div>
    <h1>New game</h1>
    <div style={{marginBottom: 16}}>
    {questions.map((question, index) => <QuestionForm
      key={index}
      question={question}
      onChange={newQuestion => {
        setQuestions(Object.assign([], questions, { [index]: newQuestion }));
      }} />)}
    </div>
    <button onClick={() => setQuestions([
      ...questions,
      createSampleQuestion()
    ])}>Add question</button>{ ' ' }
    <button onClick={() => state.socket.emit('createGame', questions)}>Create game</button>
  </div>
}

export default CreateGame;
