import React, { useState } from 'react';
import {
  useHistory,
} from "react-router-dom";

function JoinGameForm({ onSubmit }) {
  const [id, setId] = useState('');
  const history = useHistory();
  return <div>
    <h2>CodersX Ka-hút!! 👀</h2>
    <div style={{marginBottom: 16}}>
      <input type="text"
        value={id}
        placeholder="Game ID"
        onChange={e => setId(e.target.value)} />{ ' ' }
      <button style={{background: '#b31200', color: '#fff'}} onClick={() => history.push(`/game/${id}`)}>Join</button>
    </div>
    <button onClick={() => history.push('/game/create')}>New game</button>
  </div>
}

export default JoinGameForm
