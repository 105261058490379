import React, { useContext, useEffect, useState } from 'react';
import {
  useParams,
} from "react-router-dom";

import { GameContext } from '../contexts';
import checkIcon from '../assets/check.svg';
import checkBlueIcon from '../assets/check-blue.svg';

function Question({ nextQuestionAt, question, onSubmit }) {
  const [selected, setSelected] = useState(null);
  const [submitted, setSubmitted] = useState(false);
  const [countdown, setCountdown] = useState(nextQuestionAt - Date.now());
  useEffect(() => {
    setSubmitted(false);
    setSelected(null);
  }, [question.title])
  useEffect(() => {
    let intervalId;
    if (intervalId) {
      clearInterval(intervalId);
    }
    intervalId = setInterval(() => {
      setCountdown(nextQuestionAt - Date.now());
    }, 1000);

    return function() {
      clearInterval(intervalId);
    }
  }, [nextQuestionAt])
  return <div>
    <div>Remaining time: {Math.ceil(countdown/1000)}s</div>
    <h2>
      {question.title}
    </h2>
    {Object.keys(question.answers).map(key => <div key={key}>
      <div style={{display: 'flex', marginBottom: 8}}>
        <img onClick={() => setSelected(key)} src={selected === key ? checkBlueIcon : checkIcon} width={32} />
        <span>&nbsp;{key}.&nbsp;</span>
        <div
          style={{flex: 1, textAlign: 'left'}}>
          {question.answers[key]}
        </div>
      </div>
    </div>)}
    {!submitted && <button onClick={() => {
      setSubmitted(true);
      onSubmit(selected);
    } }>Next</button>}
    {submitted && <p>Please wait until the next question 😎</p>}
  </div>
}

function StateComplete({ state }) {
  return <div>
    <h2>Game over 💩💩💩</h2>
    <table style={{width: '100%'}}>
      <thead>
        <tr>
          <th>User</th>
          <th>Score</th>
        </tr>
      </thead>
      <tbody>
        {Object.keys(state.game.players).map(userId => <tr key={userId}>
          <td>{state.game.players[userId].username || userId}</td>
          <td>{state.game.players[userId].score}</td>
        </tr>)}
      </tbody>
    </table>
  </div>
}

function StateWaiting({ state, gameId }) {
  const { socket, game } = state;
  if (game.host === socket.id) {
    return <button onClick={() => socket.emit('startGame', gameId)}>Start</button>
  } else {
    return <p>Please wait a moment, host will start the game soon.</p>
  }
}

function PlayerInfo({ state, dispatch }) {
  const { socket, game } = state;
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');

  if (!socket) {
    return <p>You are not connected...</p>
  }

  return <div style={{marginBottom: 16}}>
    <p>You are connected. DO NOT RELOAD BROWSER!</p>
    { !state.email && <div style={{display: 'flex', flexDirection: 'column'}}>
      <input type="text" placeholder="Username" style={{marginBottom: 8}} onChange={e => setUsername(e.target.value)} />
      <input type="email" placeholder="Email" style={{marginBottom: 16}} onChange={e => setEmail(e.target.value)} />
      <button
        disabled={!username || !email}
        onClick={() => {
          dispatch({ type: 'setUserInfo', payload: { email, username } });
          socket.emit('setUserInfo', { email, username });
        }}>Save</button>
    </div>
    }
    { state.email && <FloatingUserInfo state={state} />}
  </div>
}

function GameInfo({ state }) {
  const {game, socket} = state;

  return <div>
    Game: { game.id }{' '}
    { game.host === socket.id && <span>(host)</span>}
    <div>Players: {Object.keys(game.players).length}</div>
  </div>
}

function FloatingUserInfo({ state }) {
  const { socket, game, username, email } = state;
  return <div className="floating-user-info">
    Socket ID: {socket.id}<br/>
    Username: {username}<br/>
    Email: {email}
  </div>
}

function Game() {
  const { id } = useParams();
  const { state, dispatch } = useContext(GameContext);
  const { socket, game } = state;

  useEffect(() => {
    socket && socket.emit('joinGame', id);
  }, [state.socket]);

  return <div>
    {state.game && <GameInfo state={state} />}
    <PlayerInfo state={state} dispatch={dispatch} />
    { state.game && <div>
      { state.game.status === 'waiting' && <StateWaiting state={state} gameId={id} />}
      { state.game.status === 'inProgress' &&
        Number.isInteger(state.game.currentQuestion) &&
        <Question
        question={state.game.questions[state.game.currentQuestion]}
        nextQuestionAt={state.game.nextQuestionAt}
        onSubmit={(selectedAnswer) => socket.emit('submitAnswer', {
          gameId: id,
          questionIndex: state.game.currentQuestion,
          answer: selectedAnswer
        })}/>
      }
      { state.game.status === 'complete' && <StateComplete state={state} />}
    </div>}
  </div>
}

export default Game;
