import React, { useEffect, useReducer } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useHistory
} from "react-router-dom";
import logo from './logo.svg';
import './App.css';
import io from 'socket.io-client';
import { JoinGameForm, Game, CreateGame } from './components';
import { GameContext } from './contexts';

const initialState = {
  socket: null,
  username: null,
  email: null,
  users: {},
  gameIdInputValue: '',
  game: null // { id, status: 'inProgress|complete', questions: [], currentQuestionIndex }
};

function reducer(state, action) {
  switch (action.type) {
    case 'socketConnected':
      return {
        ...state,
        socket: action.payload
      }
    case 'joinGameSuccess':
    case 'playerJoined':
    case 'gameStarted':
    case 'nextQuestion':
    case 'gameOver':
      return {
        ...state,
        game: action.payload
      }
    case 'setUserInfo':
      const { email, username } = action.payload;
      return {
        ...state,
        email,
        username,
      }
    case 'setUserInfoFailed':
      return {
        ...state,
        email: null,
        username: null
      }
    default:
      return state;
  }
}

function App() {
  const history = useHistory();
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    const socket = io(process.env.REACT_APP_SOCKET_URL);
    socket.on('connect', () => {
      dispatch({ type: 'socketConnected',  payload: socket });
    });
    socket.on('joinGameSuccess', (game) => {
      dispatch({ type: 'joinGameSuccess',  payload: game });
      history.push(`/game/${game.id}`)
    });
    socket.on('createGameSuccess', game => {
      history.push(`/game/${game.id}`);
    });
    socket.on('playerJoined', game => {
      dispatch({ type: 'playerJoined', payload: game });
    });
    socket.on('gameStarted', game => {
      dispatch({ type: 'gameStarted', payload: game });
    });
    socket.on('nextQuestion', game => {
      dispatch({ type: 'nextQuestion', payload: game });
    });
    socket.on('gameOver', game => {
      dispatch({ type: 'gameOver', payload: game });
    });
    socket.on('setUserInfoFailed', () => {
      dispatch({ type: 'setUserInfoFailed' });
    })
  }, []);
  return (
    <GameContext.Provider value={{ state, dispatch }}>
      <div className="App">
        <header className="App-header">
          <Switch>
            <Route path="/game/create">
              <CreateGame />
            </Route>
            <Route path="/game/:id">
              <Game />
            </Route>
            <Route path="/">
              <JoinGameForm onSubmit={(id) => state.socket.emit('joinGame', id)} />
            </Route>
          </Switch>
        </header>
      </div>
    </GameContext.Provider>
  );
}

export default App;
